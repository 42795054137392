import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {GiBroom} from '@react-icons/all-files/gi/GiBroom';
import {HiOutlineSearch} from '@react-icons/all-files/hi/HiOutlineSearch';
import classnames from 'classnames';
import useJoinMessage from '../../../hooks/useJoinMessage';
import BaseInput from '../../Core/inputs/BaseInput/BaseInput';
import './search.less';

export default function SearchComponent({
    searchOption = '',
    onChange,
    disabled,
    inputProps,
    forText,
    placeholderText = '',
    setSearchValue = () => {}
}) {
    const intl = useIntl();
    const joinMessage = useJoinMessage();
    const [searchInput, setSearchInput] = useState(searchOption || '');
    const className = classnames('search', {
        'is-empty': searchInput === '' || disabled
    });

    useEffect(() => {
        if (searchOption !== searchInput) {
            setSearchInput(searchOption);
        }
    }, [searchOption]);

    function changeSearch(e) {
        setSearchInput(e.target.value);
        setSearchValue(e.target.value);
    }

    function onSearch(e) {
        if (e.key === 'Enter' && !disabled) {
            onChange(e.target.value);
        }
    }

    function clean() {
        setSearchInput('');
        onChange('');
    }

    return (
        <div className="search-container">
            <BaseInput
                {...inputProps}
                disabled={disabled}
                className={className}
                type="text"
                placeholder={placeholderText || joinMessage('esl.generic.search')}
                inputProps={{'aria-label': joinMessage('esl.generic.search', forText)}}
                value={searchInput}
                onChange={changeSearch}
                onKeyDown={onSearch}
                classes={{
                    input: 'search-input'
                }}
                startAdornment={(
                    <InputAdornment position="start">
                        <HiOutlineSearch aria-hidden />
                    </InputAdornment>
                )}
                endAdornment={(
                    <InputAdornment position="end">
                        <Tooltip
                            placement="top"
                            title={intl.formatMessage({id: 'esl.generic.ada_mode.transaction_list.clear_search', defaultMessage: ''})}
                            arrow
                        >
                            <IconButton
                                onClick={clean}
                                aria-label={joinMessage('esl.generic.ada_mode.transaction_list.clear_search')}
                            >
                                <GiBroom className="broom-icon" aria-hidden />
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                )}
            />
        </div>
    );
}
